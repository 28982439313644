.Map {
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .Map {
    border-radius: 0px;
    width: 100%;
  }

  .MapContainer {
    width: 100%
  }
  
}