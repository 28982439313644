.HighPetitionBox {
    flex-direction: row
}

.HighPetitionImage {
    border-radius: 5px
}

.TitleBox {
    width: 500px;
}

.HorizontalDivider {
    display: none;
}

.VerticalDivider {
    display: block;
}

.Footer {
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .HighPetitionBox {
        flex-direction: column
    }

    .TitleBox {
        width: 100%;
    }

    .HorizontalDivider {
        display: block;
    }

    .VerticalDivider {
        display: none;
    }

    .Footer {
        justify-content: space-between;
        flex-direction: row;
        font-size: 12px;
    }

    .Button {
        margin-top: 10px;
    }
}

@media screen and (max-width: 360px) {
    .Footer {
        flex-direction: column;
    }
}