.ContactTagContainer {
  width: 300px;
  padding: 0
}

.Label {
  width: 300px;
  font-size: 12px;
}

.ImageBox{
  width: 42px;
}

@media screen and (max-width: 360px) {
  .ContactTagContainer {
    width: 100%;
    /* word-wrap: break-word; */
    /* height: auto; */
  }
  .ImageBox{
    width: auto;
  }
  .Label {
    width: 100%;
    font-size: 10px;
  }
}