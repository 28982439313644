.Slide {
  grid-template-columns: repeat(4, 1fr)
}

.PaginationButtons {
  display: block;
}

.Bookmark {
  max-width: 53px;
}
@media screen and (max-width: 768px) {
  .Bookmark {
    max-width: 30px;
  }
  .Slider {
    height: 371px;
    margin: auto !important;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: auto;
  }
  .SlideTrack {
    display: flex;
    width: calc(234px * 8);
  }
  .Slide {
    height: 371px;
    width: 234px;
    display: flex;
    align-items: center;
    grid-template-columns: repeat(100, 1fr);
  }
  .PaginationButtons {
    display: none;
  }
}