.DataContainerContainer {
  margin-left: 1rem;
  border-radius: 5px;
  width: 528px
}

.DataContainerBox {
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .DataContainerContainer {
    margin-left: 0rem;
    border-radius: 0px;
    width: 100%;
  }
  .DataContainerBox {
    width: 100%;
  }
}

@media screen and (max-width:360px) {
  .DataContainerBox {
    padding: 1rem 0;
  }
}