.Slide {
  grid-template-columns: repeat(4, 1fr)
}

@media screen and (max-width: 768px) {
  .Slider {
    height: 371px;
    margin: auto !important;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: auto;
  }
  .SlideTrack {
    display: flex;
    width: calc(234px * 4);
  }
  .Slide {
    height: 291px;
    width: 234px;
    display: flex;
    align-items: center;
    grid-template-columns: repeat(100, 1fr);
  }
}